<template>
  <div class="banner-container">
    <div class="banner-text-body">
      我们，让AI更简单
    </div>
  </div>
</template>

<script>
export default {
  name: 'BannerBg'
}
</script>

<style scoped lang="scss">
.banner-container {
  width: 100%;
  min-width: 768px;
  height: 61.5vh;
  background-image: url("~@/assets/images/index/pic-bg.jpg");
  background-size: 100% 100%;

  .banner-text-body {
    padding-top: 19.5vh;
    text-align: center;
    height: 140px;
    font-size: 56px;
    color: #000000;
    letter-spacing: 0;
    font-weight: 200;
  }
}
</style>
